/* eslint-disable react/no-danger */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  Text
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIMask } from 'react-imask'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { SEOComponent } from 'helpers/defaultSEO'

type FormValues = {
  nome: string
  email: string
  whatsapp: string
  aceite: boolean
}

const schema = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .email('Não é um email válido')
    .required('Email é obrigatório'),
  whatsapp: Yup.string()
    .min(18, 'Whatsapp inválido')
    .required('Whatsapp é obrigatório'),
  aceite: Yup.boolean().isTrue('É necessário aceitar para continuar')
})

function IndexPage(): JSX.Element {
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors }
  } = useForm<FormValues>({
    resolver: yupResolver(schema)
  })
  const router = useRouter()
  const [submitting, setSubmitting] = useState(false)

  const { ref } = useIMask(
    {
      mask: [{ mask: '+55 (00) 0000-0000' }, { mask: '+55 (00) 0 0000-0000' }]
    },
    { onAccept: value => setValue('whatsapp', value, { shouldDirty: true }) }
  )

  function onSubmit(data: FormValues) {
    setSubmitting(true)
    axios
      .post('/api/sheet', data)
      .then(() => {
        router.push(`/obrigado?email=${data.email}`, `/obrigado`)
      })
      .catch(() => {
        toast.error('Falha ao enviar o contato')
        setSubmitting(false)
      })
  }

  return (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '682242299547838');
                fbq('track', 'PageView');
              `
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://www.facebook.com/tr?id=682242299547838&ev=PageView&noscript=1"
          />
        </noscript>
      </Head>
      <SEOComponent />
      <Flex flexDir="column" alignItems="center" textAlign="center">
        <Stack spacing="2">
          <Text
            fontSize="3xl"
            fontWeight="black"
            color="inedita.orange.400"
            lineHeight="short"
          >
            SAIBA AGORA COMO ECONOMIZAR MUITO COM ENERGIA ELÉTRICA
          </Text>
          <Text color="inedita.blue.200" fontSize="xl">
            Sem se preocupar com contas altíssimas e bandeiras tarifárias.
          </Text>
        </Stack>

        <Text
          color="gray.200"
          fontStyle="italic"
          mt="6"
          display="block"
          w={['90%', '75%']}
        >
          É só preencher os campos abaixo e um de nossos consultores entrará em
          contato com você.
        </Text>

        <Box
          as="form"
          mt="3"
          w={['90%', '75%', '60%']}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing="2">
            <FormControl isInvalid={!!errors.nome}>
              <FormLabel>Nome</FormLabel>
              <Input bg="whiteAlpha.800" color="black" {...register('nome')} />
              {errors.nome && (
                <FormErrorMessage>{errors.nome.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                bg="whiteAlpha.800"
                color="black"
                {...register('email')}
              />
              {errors.email && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors.whatsapp}>
              <FormLabel>Whatsapp</FormLabel>
              <InputGroup>
                <Input
                  type="tel"
                  bg="whiteAlpha.800"
                  color="black"
                  {...register('whatsapp')}
                  ref={ref as React.RefObject<HTMLInputElement>}
                />
              </InputGroup>
              {errors.whatsapp && (
                <FormErrorMessage>{errors.whatsapp.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors.aceite}>
              <Checkbox
                isInvalid={!!errors.aceite}
                {...register('aceite')}
                colorScheme="orange"
              >
                Permito o uso destes dados para contato
              </Checkbox>
              {errors.aceite && (
                <FormErrorMessage>{errors.aceite.message}</FormErrorMessage>
              )}
            </FormControl>
          </Stack>
          <Button
            mt="8"
            colorScheme="orange"
            type="submit"
            bg="inedita.orange.400"
            isLoading={submitting}
            loadingText="Enviando dados..."
          >
            ENVIAR
          </Button>
        </Box>
      </Flex>
    </>
  )
}

export default IndexPage
