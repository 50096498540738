import { NextSeo, NextSeoProps } from 'next-seo'
import { useMemo } from 'react'

const url = process.env.NEXT_PUBLIC_URL

const websiteTitle = 'Inédita Energia Solar'

export const defaultSEO: NextSeoProps = {
  title: websiteTitle,
  description: 'SAIBA AGORA COMO ECONOMIZAR MUITO COM ENERGIA ELÉTRICA',
  additionalMetaTags: [
    { name: 'application-name', content: websiteTitle },
    { name: 'apple-mobile-web-app-title', content: websiteTitle },
    { name: 'msapplication-TileColor', content: '#ff7e00' },
    { name: 'theme-color', content: '#000000' },
    {
      name: 'msapplication-config',
      content: `${url}/favicon/browserconfig.xml`
    }
  ],
  additionalLinkTags: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: `${url}/favicon/apple-touch-icon.png`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: `${url}/favicon/favicon-32x32.png`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: `${url}/favicon/favicon-16x16.png`
    },
    {
      rel: 'manifest',
      href: `${url}/favicon/site.webmanifest`
    },
    {
      rel: 'mask-icon',
      color: '#9580ff',
      href: `${url}/favicon/safari-pinned-tab.svg`
    },
    {
      rel: 'shortcut icon',
      href: `${url}/favicon/favicon.ico`
    }
  ],
  openGraph: {
    type: 'website',
    site_name: websiteTitle,
    images: [
      {
        url: `${url}/favicon/android-chrome-256x256.png`,
        width: 256,
        height: 256,
        alt: websiteTitle
      }
    ]
  }
}

export function SEOComponent(data?: NextSeoProps) {
  const newData = useMemo(() => {
    // eslint-disable-next-line react/destructuring-assignment
    if (data && (!data?.title || data?.title === websiteTitle)) {
      return { ...data, titleTemplate: undefined, title: websiteTitle }
    }
    return data
  }, [data])

  return <NextSeo {...defaultSEO} {...newData} />
}
